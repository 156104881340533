const buildingAllUnits = {
  name: "property-buildings-all-units",
  path: "/accounts/:account/properties/:property/buildings/manage/all-units",
  component: () => import("@/views/propertyAllResidents/AllResidents.vue"),
  meta: {
    protected: true,
  },
  children: [
    {
      name: "all-units-unit-scoped",
      path: "details/:building/:unit",
      component: () => import("@/views/NestedRoute.vue"),
      meta: {
        protected: true,
      },
      children: [
        {
          name: "all-units-update-details",
          path: "update-details",
          component: () => import("@/views/units/ResidenceUpdateDetails.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "all-units-remove",
          path: "remove",
          component: () => import("@/views/units/ResidenceRemove.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "all-units-add-contact",
          path: "addContact",
          component: () => import("@/views/contacts/ContactCreate.vue"),
          meta: {
            protected: true,
          },
        },
      ],
    },
    {
      name: "all-units-contact-scoped",
      path: "contact/:building/:contact",
      component: () => import("@/views/NestedRoute.vue"),
      meta: {
        protected: true,
      },
      children: [
        {
          name: "all-units-contact-update-details",
          path: "updateDetails",
          component: () => import("@/views/contacts/ContactUpdateDetails.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "all-units-contact-fob-update",
          path: "updateFOB",
          component: () => import("@/views/contacts/ContactFOBUpdate.vue"),
          meta: {
            protected: true,
          },
        },
        {
          name: "all-units-contact-remove",
          path: "remove",
          component: () => import("@/views/contacts/ContactRemove.vue"),
          meta: {
            protected: true,
          },
        },
      ],
    },
  ],
}

export default buildingAllUnits
